<template>
  <div>
    <div class="ma-5 d-flex align-center">
      <v-img class="ma-5" src="@/assets/logo.png" lazy-src="@/assets/logo.png" height="100px" contain></v-img>
    </div>
    
    <v-tabs v-model="activeTab" centered dark background-color="primary">
      <v-tab v-for="tab of tabs" :key="tab.id" :to="tab.route" exact>
        {{ tab.name }}
      </v-tab>
      <v-tab-item style="background-color: #fdf8f8;" v-for="tab of tabs" :key="tab.id" :value="tab.route">
        <router-view></router-view>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeTab: "signIn",
      tabs: [
        { id: 1, name: "JOIN", route: `signUp` },
        { id: 2, name: "SIGN IN", route: `signIn` },
      ],
    };
  },
};
</script>

<style>
</style>